import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Markdown from 'react-markdown';
import './PrivacyPolicy.css';
import appLogo from './assets/app-logo.png';
import appStoreBadge from './assets/app-store-badge-en.svg';
import PP from './PrivacyPolicy.md';

function PrivacyPolicy() {
    const { t, i18n } = useTranslation();

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
    };

    const isRTL = i18n.dir() === 'rtl';

    let [content, setContent] = useState({md: ""});

    useEffect(()=> {
            fetch(PP)
                .then((res) => res.text())
                .then((md) => {
                    setContent({ md })
                })
        }, 
        []
    );

    return (
        <div className={`container ${isRTL ? 'rtl' : ''}`}>
            <header className="header">
                <a href="/">
                    <img src={appLogo} alt="App Logo" className="logo" />
                </a>
                <div className='header-content'>
                    <h1 className='app-title'>{t('appTitle')}</h1>
                    <h2 className='app-subtitle'>{t('appSubtitle')}</h2>
                </div>
            </header>
            <main className='main'>
                <section className='privacy-policy-section'>
                    <Markdown children={content.md} />
                </section>
                <div className='container-centered'>
                    <h3>{t('punchline')}</h3>
                    <a href="https://apps.apple.com/il/app/run-trainer-runright/id6450888339" id='appStoreLink'>
                        <img src={appStoreBadge} alt="Download from the Apple Store" className="badge" />
                    </a>
                </div>
            </main>
        </div>
    );
}

export default PrivacyPolicy;