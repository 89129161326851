import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import enTranslation from './locales/en.json';

const resources = {
  en: {
    translation: enTranslation,
  }
};

const defaultLanguage = navigator.language.split('-')[0];

i18n.use(initReactI18next).init({
  resources,
  lng: defaultLanguage, // Set the default language based on the user's location
  fallbackLng: 'en', // Fallback language if translation is missing for the selected language
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
