import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import './Home.css';
import appLogo from './assets/app-logo.png';
import appStoreBadge from './assets/app-store-badge-en.svg';
import photo1 from './assets/IMG_9068.png';
import photo2 from './assets/IMG_9069.png';
import photo3 from './assets/IMG_9070.png';
import photo4 from './assets/IMG_9071.png';
import photo5 from './assets/IMG_9075.png';
import photo6 from './assets/IMG_9081.png';

function Home() {
    const { t, i18n } = useTranslation();

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
    };

    const isRTL = i18n.dir() === 'rtl';

    return (
        <div className={`container ${isRTL ? 'rtl' : ''}`}>
            <header className="header">
                <img src={appLogo} alt="App Logo" className="logo" />
                <div className='header-content'>
                    <h1 className='app-title'>{t('appTitle')}</h1>
                    <h2 className='app-subtitle'>{t('appSubtitle')}</h2>
                </div>
            </header>
            <main className='main'>
                <section className='photo-section'>
                    <ul className="photo-list">
                        <li><img src={photo1} alt="Photo 1" className="small-screen-photo" /></li>
                        <li><img src={photo2} alt="Photo 2"className="small-screen-photo" /></li>
                        <li><img src={photo3} alt="Photo 3" className="small-screen-photo" /></li>
                        <li><img src={photo4} alt="Photo 4" className="small-screen-photo" /></li>
                        <li><img src={photo5} alt="Photo 5" className="small-screen-photo" /></li>
                        <li><img src={photo6} alt="Photo 6" className="small-screen-photo" /></li>
                    </ul>
                </section>
                <div className='container-centered'>
                    <h3>{t('punchline')}</h3>
                    <a href="https://apps.apple.com/il/app/run-trainer-runright/id6450888339" id='appStoreLink'>
                        <img src={appStoreBadge} alt="Download from the Apple Store" className="badge" />
                    </a>
                </div>
                <p className='description'>{t('description')}</p>
            </main>
            <footer>
                <p>For support, or any other questions, you can contact us at runright.ai@gmail.com</p>
            </footer>
        </div>
    );
}

export default Home;
